// src/pages/HomePage.js
import React from "react";
import { Link } from "react-router-dom";
import tShirt1 from "../assets/images/tshirts/1.jpeg";
import tShirt2 from "../assets/images/tshirts/2.jpeg";
import tShirt3 from "../assets/images/tshirts/3.jpeg";

const HomePage = () => {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero bg-primary text-white text-center py-5">
        <div className="container">
          <h1 className="display-4">Welcome to Seven Clothing</h1>
          <p className="lead">
            Elevate your style with our premium t-shirts collection.
          </p>
          <Link to="/products" className="btn btn-light btn-lg">
            Shop Now
          </Link>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className="featured-products py-5">
        <div className="container">
          <h2 className="text-center mb-4">Featured T-Shirts</h2>
          <div className="row">
            {/* Product Cards */}
            {[tShirt1, tShirt2, tShirt3].map((image, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div className="card h-100">
                  <img
                    src={image}
                    className="card-img-top"
                    alt={`Featured T-Shirt ${index + 1}`}
                    style={{ objectFit: "cover", height: "200px" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">T-Shirt {index + 1}</h5>
                    <p className="card-text">₹{(index + 1) * 500 + 100}</p>
                    <Link
                      to={`/products/${index + 1}`}
                      className="btn btn-primary"
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about-us bg-light py-5">
        <div className="container">
          <h2 className="text-center mb-4">About Seven Clothing</h2>
          <p>
            At Seven Clothing, we believe that great fashion starts with
            high-quality, stylish t-shirts. Our collection is curated to offer
            something for everyone, from classic whites to trendy graphic
            prints. Our commitment to quality and design ensures that you not
            only look good but feel great. Explore our range and discover your
            new favorite t-shirt today!
          </p>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta bg-secondary text-white text-center py-5">
        <div className="container">
          <h2 className="mb-4">Stay Updated</h2>
          <p>
            Sign up for our newsletter to get the latest updates on new
            arrivals, special offers, and more!
          </p>
          <Link to="/contact" className="btn btn-light btn-lg">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
