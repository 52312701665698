// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-5 pb-4">
      <div className="container">
        <div className="row">
          {/* Quick Links Section */}
          <div className="col-md-3 mb-4">
            <h5 className="text-uppercase">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-white text-decoration-none">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  className="text-white text-decoration-none"
                >
                  Products
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-white text-decoration-none">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white text-decoration-none">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info Section */}
          <div className="col-md-3 mb-4">
            <h5 className="text-uppercase">Contact Us</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="mailto:support@sevenclothing.in"
                  className="text-white text-decoration-none"
                >
                  support@sevenclothing.in
                </a>
              </li>
              <li>
                <a
                  href="tel:+917014640375"
                  className="text-white text-decoration-none"
                >
                  +91-7014640375
                </a>
              </li>
              <li>Jaipur, Rajasthan, INDIA</li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div className="col-md-3 mb-4">
            <h5 className="text-uppercase">Follow Us</h5>
            <div className="d-flex">
              <a
                href="https://facebook.com"
                className="text-white me-3"
                aria-label="Facebook"
                target="_blank"
              >
                <FaFacebookF size={24} />
              </a>
              <a
                href="https://twitter.com"
                className="text-white me-3"
                aria-label="Twitter"
                target="_blank"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://instagram.com"
                className="text-white me-3"
                aria-label="Instagram"
                target="_blank"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://linkedin.com"
                className="text-white"
                aria-label="LinkedIn"
                target="_blank"
              >
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div>

          {/* Newsletter Signup Section */}
          <div className="col-md-3 mb-4">
            <h5 className="text-uppercase">Newsletter</h5>
            <p>
              Sign up for our newsletter to receive the latest updates and
              offers.
            </p>
            <form action="#">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email Address"
                  aria-label="Email Address"
                />
              </div>
              <button type="submit" className="btn btn-light w-100">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center mt-4">
          <p className="mb-0">
            © {new Date().getFullYear()} Seven Clothing. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
