// src/components/ProductGrid.js
import React, { useState } from "react";
import ContactModal from "./ContactModal";
import tShirt1 from "../assets/images/tshirts/1.jpeg";
import tShirt2 from "../assets/images/tshirts/2.jpeg";
import tShirt3 from "../assets/images/tshirts/3.jpeg";
import tShirt4 from "../assets/images/tshirts/4.jpeg";

const products = [
  {
    id: 1,
    name: "Classic White T-Shirt",
    price: "₹499",
    imageUrl: tShirt1,
  },
  {
    id: 2,
    name: "Black V-Neck T-Shirt",
    price: "₹599",
    imageUrl: tShirt2,
  },
  {
    id: 3,
    name: "Graphic Printed T-Shirt",
    price: "₹699",
    imageUrl: tShirt3,
  },
  {
    id: 4,
    name: "Red Round Neck T-Shirt",
    price: "₹799",
    imageUrl: tShirt4,
  },
];

const ProductGrid = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSelect = (product) => {
    setSelectedProduct(product);
  };

  return (
    <div className="container mt-5">
      <div className="row">
        {products.map((product) => (
          <div className="col-md-3 mb-4" key={product.id}>
            <div className="card h-100">
              <img
                src={product.imageUrl}
                className="card-img-top"
                alt={product.name}
                style={{ objectFit: "cover", height: "200px" }}
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">{product.price}</p>
                <button
                  className="btn btn-primary mt-auto"
                  data-bs-toggle="modal"
                  data-bs-target="#contactModal"
                  onClick={() => handleSelect(product)}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal should be mounted once, not conditionally */}
      <ContactModal selectedProduct={selectedProduct} />
    </div>
  );
};

export default ProductGrid;
