import React, { useState } from "react";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here
    // For example, sending data to an API or email service
    console.log("Form data submitted:", formData);
    setStatus("Thank you for reaching out! We will get back to you soon.");
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="contact-page">
      {/* Hero Section */}
      <section className="hero bg-primary text-white text-center py-5">
        <div className="container">
          <h1 className="display-4">Contact Us</h1>
          <p className="lead">We’d love to hear from you!</p>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="contact-form py-5">
        <div className="container">
          <h2 className="text-center mb-4">Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
          {status && <div className="alert alert-success mt-3">{status}</div>}
        </div>
      </section>

      {/* Contact Information Section */}
      <section className="contact-info bg-light py-5">
        <div className="container">
          <h2 className="text-center mb-4">Our Address</h2>
          <p className="text-center">
            Seven Clothing
            <br />
            Jaipur, Rajasthan, 302006
            <br />
            INDIA
          </p>
          <p className="text-center">
            <strong>Email:</strong> support@sevenclothing.in
            <br />
            <strong>Phone:</strong> +91-7014640375
          </p>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
