// src/pages/ProductDetails.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ContactModal from "../components/ContactModal";
import tShirt1 from "../assets/images/tshirts/1.jpeg";
import tShirt2 from "../assets/images/tshirts/2.jpeg";
import tShirt3 from "../assets/images/tshirts/3.jpeg";

const productImages = [tShirt1, tShirt2, tShirt3];

const ProductDetails = () => {
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState({
    id: parseInt(id),
    name: `T-Shirt ${id}`,
    price: `₹${id * 500 + 100}`,
    imageUrl: productImages[id - 1],
  });

  const handleOrderNow = () => {
    // Trigger the modal to open
    const modal = new window.bootstrap.Modal(
      document.getElementById("contactModal")
    );
    modal.show();
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-6 mb-4">
          <img
            src={selectedProduct.imageUrl}
            alt={selectedProduct.name}
            className="img-fluid rounded shadow-sm"
            style={{ maxHeight: "400px", objectFit: "cover" }}
          />
        </div>
        <div className="col-md-6">
          <h1 className="mb-3">{selectedProduct.name}</h1>
          <h3 className="mb-4">{selectedProduct.price}</h3>
          <p className="mb-4">
            Description: This is a detailed description of the{" "}
            {selectedProduct.name}. It highlights the unique features and
            quality of the t-shirt to attract potential buyers.
          </p>
          <button className="btn btn-primary btn-lg" onClick={handleOrderNow}>
            Order Now
          </button>
        </div>
      </div>
      <div className="mt-5">
        {/* Additional Content or Related Products Section */}
      </div>

      {/* Modal should be mounted once, not conditionally */}
      <ContactModal selectedProduct={selectedProduct} />
    </div>
  );
};

export default ProductDetails;
